#Header {
  width: 100%;
  background-image: url("./../images/webo-header.jpg");
  height: 125px;
  margin-bottom: 20px;
  background-size: contain;
  background-color: #b7132b;
  background-repeat: no-repeat;
  background-position: left center;
}

#AdminEditor {
  position: fixed !important;
  bottom: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0px -3px 20px rgba(0, 0, 0, 0.5);
  background-color: white !important;
  z-index: 100000;
}
#AdminEditor .row {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.header-background {
  background-color: #919191 !important;
  color: white !important;
}
.align-vertical-center {
  display: flex !important;
  align-items: center !important;
}
.excel-table {
  border: none !important;
  font-family: sans-serif !important;
  font-size: 12px;
}
.table-container {
  max-height: calc(100vh - 450px) !important;
  overflow-x: auto;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  overflow-y: scroll;
}

.table-formats {
  border: none !important;
}
.table-container-formats {
  max-height: calc(100vh - 350px) !important;
  overflow-x: auto;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  overflow-y: scroll;
  margin-bottom: 20px;
}
.table-container-richmedia {
  padding-bottom: 30px;
  padding-top: 30px;
  padding-right: 10px;

  max-height: calc(100vh - 350px) !important;
  overflow-x: hidden;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  border-bottom: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 5px;
  overflow-y: scroll;
  margin-bottom: 20px;
}
.full {
  position: relative;
  height: 36px;
  line-height: 36px;
  display: block;
}

.nopad-nomargin button {
  margin: 0px !important;
  padding: 0px !important;
}

.editor-menu {
  position: fixed !important;
  z-index: 100 !important;
  bottom: 20px;
  right: 20px;
}
.a-link {
  color: #b7132b !important;
  cursor: pointer;
}

.header-section {
  text-align: right;
  padding-top: 60px;
  padding-right: 50px;
  color: white;
  font-size: 30px;
  font-weight: bold;
  font-family: "Montserrat" !important;
}
.upload-field {
  cursor: pointer;
}
.button-input-excel button {
}
.spec {
  font-size: 16px;
}

.menu-link-active {
  color: white !important;
  font-weight: bold;
}
.item.active {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.spec .value {
  margin-left: 10px;
  font-weight: bold;
}

.tiny-text {
  font-size: 11px;
  color: rgba(0, 0, 0, 0.5);
}
.toggle-editor {
  position: absolute;
  right: 20px;
}
.row-link {
  cursor: pointer;
}
.show {
  display: block !important;
}
.hide {
  display: none !important;
}
.html-input {
  color: white;
  background: rgba(0, 0, 0, 0.8);
  padding: 20px;
  font-style: italic;
  font-family: "Raleway", monospace;
  border-radius: 5px;
  width: 100% !important;
  min-height: 600px;
  max-height: 600px;
  height: 100% !important;
  border: none;
}
.html-input::selection {
  color: black;
  background: yellow;
}

.html-output {
  border: none;
  border-radius: 5px;
  font-style: italic !important;
  font-family: "Raleway", monospace;
  border-radius: 5px;
  border: none;
}

.html-output.full {
  border-radius: 0px !important;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw !important;
  margin: 0px !important;
  padding: 0px !important;
  height: 100vh !important;
  left: 0px !important;
  top: 0px !important;
  z-index: 10000 !important;
  position: fixed !important;
}

.html-output.minimized {
  background-color: rgba(0, 0, 0, 0.1);
  width: 100% !important;
  height: 50vh !important;
  min-height: 600px;
  position: relative !important;
}
.button.full {
  left: 20px !important;
  bottom: 20px !important;
  z-index: 10001 !important;
  position: fixed !important;
}

.tracker-input {
  padding-top: 10px;
}

.hidden-inputs {
  visibility: hidden;
}

.FeedTable td {
  max-width: 20vw;
  max-height: 100px !important;
}
.FeedTable td textarea {
  border: 0px !important;
  background-color: transparent;
  width: 100%;
}

.FeedTable tr {
  max-height: 100px !important;
}
.FeedTable .table-row {
  height: 100px !important;
  max-height: 100px !important;
}

.iframe-preview {
  float: left;
  margin: 5px;
}

.values-ls iframe {
  height: 50px !important;
  width: 100%;
}

.ad {
  font-weight: 300;
  float: left !important;
}
.type {
  font-weight: bold !important;
}
.iframe-loaded-rule {
  position: absolute;
  width: 600px;
  height: 400px;
  top: 600px;
  left: 100px;
}
.sumup {
  font-size: 12px !important;
}
.sumup .highlight {
  font-family: sans-serif !important;
  font-style: italic;
  font-size: 12px !important;
  color: #000;
}
